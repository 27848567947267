<template>
    <a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 text-right">
                        <a-button class="btn btn-danger" icon="close" v-if="applySurvey" @click="onCancelSurvey">Cancelar encuesta</a-button>
                        <a-button class="btn btn-info" icon="edit" v-else @click="onInitSurvey">Aplicar encuesta</a-button>
                        <!-- <a-button class="btn btn-warning ml7" icon="unordered-list" v-if="!applySurvey && !showResults" @click="onShowResults">Resultados</a-button>
                        <a-button class="btn btn-warning ml7" v-else @click="cancelShowResults">Regresar</a-button> -->
                        <a-button class="btn btn-success ml7" icon="reload" @click="initModule" v-if="!applySurvey && !showResults" />
                    </div>
                </div>
                <surveyDetail ref="surveyDetail" :userSurvey="userSurvey" :applySurvey="applySurvey" @onCleanLocalData="cleanLocalData" @onCancel="onCancelSurvey" v-if="!showResults" />
                <surveyResults ref="surveyResults" @onCancel="cancelShowResults" v-else />
            </div>
        </div>
    </a-spin>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
//
import surveyDetail from '@/components/surveys/detail'
import surveyResults from '@/components/surveys/results'

export default {
    name: 'SurveysView',
    components: {
        surveyDetail,
        surveyResults,
    },
    computed: {
        ...mapGetters('surveys', ['spinnerLoader', 'spinnerLoaderLabel']),
        ...mapGetters('users', ['usersList']),
    },
    data() {
        return {
            applySurvey: false,
            userSurvey: undefined,
            showResults: false,
        }
    },
    methods: {
        initModule() {
            this.$store.dispatch('surveys/GET_ONE', this.$route.params.id)
        },
        cleanLocalData() {
            this.applySurvey = false
            this.userSurvey = undefined
        },
        onShowResults() {
            this.showResults = true
        },
        cancelShowResults() {
            this.showResults = false
        },
        onInitSurvey() {
            let users = {}
            this.usersList.map((element) => {
                users[element.user_id] = element.fullName
            })

            Swal.fire({
                title: 'Selecciona colaborador a evaluar',
                input: 'select',
                inputOptions: {
                    ...users,
                },
                inputPlaceholder: 'Selecciona',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#41b883',
                confirmButtonText: 'Continuar',
                cancelButtonColor: '#f5222e',
                cancelButtonText: 'Cancelar',
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (!value) {
                            resolve('Selecciona colaborador')
                        }
                        resolve()
                    })
                },
            }).then((resolve) => {
                if (resolve.isConfirmed) {
                    this.userSurvey = resolve.value
                    this.applySurvey = true
                }
            })
        },
        onCancelSurvey() {
            Swal.fire({
                title: 'Atención',
                text: '¿Confirmas cancelar el registro?',
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#41b883',
                confirmButtonText: 'Sí, continuar',
                cancelButtonColor: '#f5222e',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.cleanLocalData()
                }
            })
        },
    },
}
</script>