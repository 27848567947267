<template>
    <div>
        <div class="row pt10">
            <div class="col-md-12">
                <h2>{{ actualSurvey.name }}</h2>
            </div>
        </div>
        <div class="row" v-for="section, index in actualSurvey.sections" :key="index">
            <div class="col-md-12">
                <div class="bgGreen p10">
                    {{ section.name }}
                </div>
                <div class="p10">
                    <div class="row" v-for="element, indexElement in section.surveyElements" :key="indexElement">
                        <div class="col-lg-10">
                            {{ element.index }}.- {{ element.question }}
                        </div>
                        <div class="col-lg-2 text-center pt10" v-if="applySurvey">
                            <a-radio-group default-value="" button-style="solid" v-model="payload[element.id]">
                                <a-radio-button value="1">
                                    Sí
                                </a-radio-button>
                                <a-radio-button value="0">
                                    No
                                </a-radio-button>
                            </a-radio-group>
                        </div>
                        <div class="col-lg-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="applySurvey">
            <div class="col-md-12 text-right">
                <a-button class="btn btn-success" icon="save" @click="onSaveSurvey">Completar encuesta</a-button>
                <a-button class="btn btn-danger ml7" icon="close" @click="onCancelSurvey">Cancelar encuesta</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'

export default {
    name: 'SurveysView',
    computed: {
        ...mapGetters('surveys', ['spinnerLoader', 'spinnerLoaderLabel', 'actualSurvey']),
        ...mapGetters('users', ['usersList']),
    },
    props: {
        userSurvey: {
            default: undefined,
        },
        applySurvey: {
            default: undefined,
        },
    },
    data() {
        return {
            payload: {},
        }
    },
    beforeMount() {
        if (!this.usersList.length) {
            this.$store.dispatch('users/GET')
        }
    },
    mounted() {
        this.$store.dispatch('surveys/GET_ONE', this.$route.params.id)
    },
    methods: {
        cleanLocalData() {
            this.payload = {}
            this.$emit('onCleanLocalData')
        },
        onCancelSurvey() {
            this.$emit('onCancel')
        },
        onSaveSurvey() {
            let errors = []

            this.actualSurvey.sections.forEach(section => {
                section.surveyElements.forEach(element => {
                    if (!this.payload[element.id]) {
                        errors.push(`Pregunta ${element.id} sin respuesta`)
                    }
                });
            });

            if (errors.length) {
                Swal.fire({
                    title: 'Atención',
                    text: 'Debes responder todos los reactivos',
                })
                return false
            }

            Swal.fire({
                title: 'Atención',
                text: '¿Confirmas guardar el registro?',
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#41b883',
                confirmButtonText: 'Sí, continuar',
                cancelButtonColor: '#f5222e',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    //
                    this.$store.dispatch('surveys/SAVE', {
                        idSurvey: this.$route.params.id,
                        userSurvey: this.userSurvey,
                        answers: this.payload,
                    }).then(response => {
                        Swal.fire({
                            title: 'Atención',
                            text: response.message,
                            icon: 'success',
                        }).then(() => {
                            this.cleanLocalData()
                        })
                    })
                }
            })
        },
    },
}
</script>